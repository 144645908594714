import React from "react";
import styles from './VerPublicacao.module.css'
import { useContext } from "react";
import { MeuContext } from "../Context";
import Post from "../Componets/Post";


export function VerPublicacao(){
const [contexto,setContexto]=useContext(MeuContext)
    return(
        <div className={styles.div}  style={{paddingTop:100}}>
<Post post={contexto}/>
        </div>
    )
}