import React, { useState, useEffect } from "react";
import { Link, Route } from "react-router-dom";
import { PostsRecentes } from "../Componets/PostsRecentes";
import styles from "./Home.module.css";
import { Banner } from "../Componets/Banner";

export function Home() {
  const [recentPosts, setRecentPosts] = useState([]);

  // useEffect(() => {
  //   carregarPublicacoes()
  //   // const fetchRecentPosts = async () => {
  //   //   const posts = [        {          id: 1,          title: "Título do post 1",          image: "https://via.placeholder.com/150",          excerpt: "Este é um resumo do post 1."        },        {          id: 2,          title: "Título do post 2",          image: "https://via.placeholder.com/150",          excerpt: "Este é um resumo do post 2."        },        {          id: 3,          title: "Título do post 3",          image: "https://via.placeholder.com/150",          excerpt: "Este é um resumo do post 3."        }      ];
  //   //   setRecentPosts(posts);
  //   // };
  //   // fetchRecentPosts();
  // }, []);
  // function carregarPublicacoes() {
  //   const dbRef = ref(getDatabase());
  //   get(child(dbRef, `Publicacoes/`))
  //     .then((snapshot) => {
  //       const mensagens = [];
  //       snapshot.forEach((mensagem) => {
  //         // console.log("O valor :", mensagem.val());
  //         mensagens.push(mensagem.val());
  //       });
  //       mensagens.sort((a, b) => new Date(b.date.split('-').reverse().join('-')) - new Date(a.date.split('-').reverse().join('-')));
  //       setRecentPosts(mensagens);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }
  const listItens = recentPosts ? (
    recentPosts.map((item) => (
      <div>
        <Link
          style={{ textDecoration: `none` }}
          onClick={() => {
            // setContexto(item);
          }}
          to={{ pathname: "/VerPublicacao" }}>
          <Banner post={item} />
        </Link>
      </div>
    ))
  ) : (
    <img
      style={{ display: "flex", width: 200, height: 200 }}
      src={
        "http://portal.ufvjm.edu.br/a-universidade/cursos/grade_curricular_ckan/loading.gif"
      }
    />
  );
  return (
    <div className={styles.container}>
      <section className={styles.bemVindo}>
        <h1>Bem-vindo ao Meu Blog!</h1>
        
        <p className={styles.bemVindo_h1}>
          Este é o lugar onde compartilho minhas ideias e opiniões sobre<br/>
          diversos assuntos relacionados ao meu interesse, incluindo tecnologia,<br/>
          cultura e entretenimento.<br/>
        </p>
        <p className={styles.bemVindo_h1}>
          Além disso, também escrevo sobre minhas experiências pessoais e<br/>
          profissionais, incluindo projetos que estou trabalhando e desafios que<br/>
          enfrento em minha jornada.<br/>
        </p>
        <p className={styles.bemVindo_h1}>
          Espero que você goste do conteúdo do meu blog e sinta-se livre para<br/>
          deixar seus comentários e sugestões para que eu possa melhorar ainda<br/>
          mais. Agradeço pela sua visita e espero vê-lo novamente em breve!<br/>
        </p>
        <section className={styles.social}>
          <h2>Conecte-se comigo nas redes sociais</h2>
          <div className={styles.contRedes}>
            <a
              className={styles.redesImg}
              href="https://www.linkedin.com/in/josias-rodrigues-708b191b2/">
              <img
                className={styles.redesImg}
                src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
              />
            </a>
            <a className={styles.redes} href="https://github.com/JosiasAOR">
              <img
                className={styles.redesImg}
                src="https://icones.pro/wp-content/uploads/2021/06/icone-github-grise.png"
              />
            </a>
            <a
              className={styles.redes}
              href="https://www.instagram.com/josiasaor/">
              <img
                className={styles.redesImg}
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png"
              />
            </a>
          </div>
        </section>
      </section>
      
      <PostsRecentes />
    </div>
  );
}
