import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import styles from "./Header.module.css";
import imgagemEscolhida from "../Imagens/logo.png";
// import foto from '..Imagens/pages.png'
export function Header() {

  return (
    <header className={styles.HeaderNav}>
      <div className={styles.container}>
        <nav role="navigation">
          <div s className={styles.menuToggle}>
            <input className={styles.input} type="checkbox" />

            <span className={styles.span}></span>
            <span className={styles.span}></span>
            <span className={styles.span}></span>

            <ul className={styles.menu}>
              <a href="#">
                <Link to="/" style={{ textDecoration: "none" }}>
                  <li>Home</li>
                </Link>
              </a>
              <a href="#">
                <Link to="Publicacoes" style={{ textDecoration: "none" }}>
                  <li>Publicações</li>
                </Link>
              </a>
              <a href="#">
                <Link to="Projetos" style={{ textDecoration: "none" }}>
                  <li>Projetos</li>
                </Link>
              </a>
              <a href="#">
                <Link to="Contact" style={{ textDecoration: "none" }}>
                  <li>Sobre Mim</li>
                </Link>
              </a>
            </ul>
          </div>
        </nav>

        <ul className={styles.ul}>
          <nav className={styles.Nav}>
            <Link to="/" style={{ textDecoration: "none" }}>
              <a className={styles.botao}>Home</a>
            </Link>
            <Link to="Publicacoes" style={{ textDecoration: "none" }}>
              <a className={styles.botao}>Publicações</a>
            </Link>
            <Link to="Projetos" style={{ textDecoration: "none" }}>
              <a className={styles.botao}>Projetos</a>
            </Link>
            <Link to="Contact" style={{ textDecoration: "none" }}>
              <a className={styles.botao}>Sobre Mim</a>
            </Link>
          </nav>
        </ul>

        <img className={styles.img} src={imgagemEscolhida}></img>
      </div>
    </header>
  );
}