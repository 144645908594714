import React from "react";
import styles from "./Projetos.module.css";
import { useContext, useState } from "react";
import { MeuContext } from "../Context";


export function Projetos() {
const [userDados,setUserDados]=useContext(MeuContext)

  return (
<div className={styles.container} style={{paddingTop:100}}>
  <h1 style={{ textAlign:'center',fontSize:22,color:'white'}} >Em breve projetos aqui!

  </h1>

</div>

  )
  
  
  
}
