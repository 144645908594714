import { useState, useEffect, useContext } from "react";
import { getDatabase, ref, child, get, set } from "firebase/database";
import { Link, Route } from "react-router-dom";
import { MeuContext } from "../Context";
import styles from './PostsRecentes.module.css'

export function PostsRecentes() {
  const [Publicacoes, setPublicacoes] = useState(null);
  const [contexto, setContexto] = useContext(MeuContext);

  useEffect(() => {
    carregarPublicacoes();
  }, []);
  function carregarPublicacoes() {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `Publicacoes/`))
      .then((snapshot) => {
        const mensagens = [];
        snapshot.forEach((mensagem) => {
          mensagens.push(mensagem.val());
        });
        mensagens.sort(
          (a, b) =>
            new Date(b.date.split("-").reverse().join("-")) -
            new Date(a.date.split("-").reverse().join("-"))
        );
        if (mensagens.length > 3) {
          setPublicacoes(mensagens.slice(0, 3));
        } else {
          setPublicacoes(mensagens);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  //////////////lista///////////////////
  const listItens = Publicacoes ? (
    Publicacoes.map((item) => (
      <div>
        <Link
          style={{ textDecoration: `none` }}
          onClick={() => {
            setContexto(item);
          }}
          to={{ pathname: "/VerPublicacao" }}>
          <div className={styles.listDiv} >
          <img className={styles.img} src={item.image}/>
            <h3 style={{position:'absolute',width:290,fontSize:22}}>{item.content.substr(item.content, 100)}...</h3>
          </div>
        </Link>
      </div>
    ))
  ) : (
    <img
      style={{ display: "flex", width: 100, height: 100 }}
      src={
        "http://portal.ufvjm.edu.br/a-universidade/cursos/grade_curricular_ckan/loading.gif"
      }
    />
  );
  return (
    <div className={styles.container}>
      <h1 className={styles.text}>Post Recentes</h1>
      <div className={styles.lista} >
      {listItens}

      </div>
    </div>
  );
}
