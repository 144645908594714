import { BrowserRouter, Routes, Route } from "react-router-dom";
import { SobreMim } from "./Pages/SobreMim";
import { initializeApp } from "firebase/app";
import { Home } from "./Pages/Home";
import { Header } from "./Componets/Header";
import { Huuu } from "./Componets/h.js";
import { Projetos } from "./Pages/Projetos";
import { Publicacoes } from "./Pages/Publicacoes";
import { firebaseConfig } from "./Firebase";
import { VerPublicacao } from "./Pages/VerPublicacao";
import { createContext, useState, useContext } from "react";
import { MeuContext } from "./Context";
import { Footer } from "./Componets/Footer";
import styles from "./App.module.css";

const database = initializeApp(firebaseConfig);
try {
  initializeApp(firebaseConfig);
} catch (e) {
  console.log("App em carregamento");
}

function App() {
  const [dadosUser, setDadosUser] = useState("Raulino macho vieira");
  const Teste1 = {
    nome: "josias",
    idade: "22",
  };

  return (
      <MeuContext.Provider value={[dadosUser, setDadosUser]}>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/VerPublicacao" element={<VerPublicacao />} />
            <Route path="/Publicacoes" element={<Publicacoes />} />
            <Route path="/Projetos" element={<Projetos />} />
            <Route path="/Contact" element={<SobreMim />} />
            <Route path="/" element={<Home />} />
          </Routes>
          <Footer/>
        </BrowserRouter>
      </MeuContext.Provider>
  );
}

export default App;
