import { createContext } from "react";

const MeuContext = createContext([]);

export { MeuContext };

const posts = [
  {
    theme: "Projeto pessoal: Aplicativo de Receitas",
    category: "Desenvolvimento de Software",
    content: `Este é um projeto pessoal que desenvolvi para aprimorar minhas habilidades
       em React Native. O aplicativo permite ao usuário buscar receitas e salvar suas
        receitas favoritas. Além disso, ele também possui uma lista de compras integrada 
        para ajudar o usuário a manter o controle dos ingredientes necessários para cada 
        receita.`,
    images: [
      "https://i.imgur.com/e0uK7VH.png",
      "https://i.imgur.com/zLmQF1U.png",
      "https://i.imgur.com/I0RxL0z.png",
    ],
    demoLink: "https://exemplo.com/app-receitas",
  },
  {
    theme: "Mundo do Bodybuilding",
    category: "Saúde e Fitness",
    content: `Este é um tópico que sempre me interessou e dediquei muito tempo para entender melhor. 
      Compartilho minhas descobertas sobre nutrição, treinamento e suplementação no meu blog pessoal,
       e gostaria de mostrar um pouco desse conhecimento aqui também. Acredito que a combinação de boa
        saúde física e mental é essencial para o sucesso em todas as áreas da vida.`,
    images: [
      "https://i.imgur.com/dWgDYqf.jpg",
      "https://i.imgur.com/gNStiU6.jpg",
      "https://i.imgur.com/U6WeJU6.jpg",
    ],
    blogLink: "https://exemplo.com/blog-bodybuilding",
  },
  {
    theme: "Coisas do mundo dev",
    category: "Tecnologia",
    content: `Gosto de estar sempre por dentro das últimas novidades e tendências do mundo da tecnologia
       e compartilho minhas descobertas com meus seguidores no Twitter. Além disso, também gosto de
        participar de eventos e comunidades online para aprender ainda mais sobre o assunto.`,
    images: [
      "https://i.imgur.com/RlqrPtI.png",
      "https://i.imgur.com/QHgFtq3.png",
      "https://i.imgur.com/FHszsA4.png",
    ],
    twitterLink: "https://twitter.com/exemplo",
  },
];
