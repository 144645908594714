import React from 'react';
import styles from './Footer.module.css'
export function Footer() {
  return (
    <footer>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.col_md_4}>
            <h3>Contato</h3>
            <p>Email: josiasaor@gmail.com</p>
            <p>Telefone: (48) 9 9977-1430</p>
          </div>
          <div className={styles.col_md_4}>
            <h3>Links úteis</h3>
            <ul>
              <li><a href="#">Termos de uso</a></li>
              <li><a href="#">Política de privacidade</a></li>
              <li><a href="#">Sobre nós</a></li>
            </ul>
          </div>
          <section className={styles.col_md_4}>
          <h2>Conecte-se comigo nas redes sociais</h2>
          <div className={styles.contRedes}>
            <a
              className={styles.redesImg}
              href="https://www.linkedin.com/in/josias-rodrigues-708b191b2/">
              <img
                className={styles.redesImg}
                src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
              />
            </a>
            <a className={styles.redes} href="https://github.com/JosiasAOR">
              <img
                className={styles.redesImg}
                src="https://icones.pro/wp-content/uploads/2021/06/icone-github-grise.png"
              />
            </a>
            <a
              className={styles.redes}
              href="https://www.instagram.com/josiasaor/">
              <img
                className={styles.redesImg}
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png"
              />
            </a>
          </div>
        </section>
        </div>
      </div>
    </footer>
  );
}

