import React from "react";
import styles from "./Post.module.css";

const Post = ({ post }) => (
  <div className={styles.postContainer}>
    <div className={styles.postContainer1}>
      <h2 className={styles.postTheme}>{post.theme}
      <br></br>ID:{post.id}
      </h2>
      <div className={styles.dateEndCtg}>
        <p className={styles.postCategory}>{post.category}</p>
        <p className={styles.postDate}>{post.date}</p>
      </div>
      <div className={styles.postContent}>
        <img className={styles.img} src={post.image} />
        <p className={styles.content}>{post.content}</p>
      </div>
    </div>
  </div>
);
export default Post;
