import React from "react";
import styles from "./SobreMim.module.css";
export function SobreMim() {
  return (
    <div className={styles.container}>
    <div className={styles.container2}>
      <div className={styles.contImg}>
          <img
            className={styles.fotoPerfil}
            src="https://avatars.githubusercontent.com/u/103387719?v=4"
          />

        <div className={styles.contRedes}>
          <a
            className={styles.redesImg}
            href="https://www.linkedin.com/in/josias-rodrigues-708b191b2/"
          >
            <img
              className={styles.redesImg}
              src="https://cdn-icons-png.flaticon.com/512/174/174857.png"
            />
          </a>
          <a className={styles.redes} href="https://github.com/JosiasAOR">
            <img
              className={styles.redesImg}
              src="https://icones.pro/wp-content/uploads/2021/06/icone-github-grise.png"
            />
          </a>
          <a
            className={styles.redes}
            href="https://www.instagram.com/josiasaor/"
          >
            <img
              className={styles.redesImg}
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png"
            />
          </a>
        </div>
      </div>
      <div className={styles.texto}>
        <h1>Sobre Mim </h1>

        <p>
          Oi, meu nome é Josias Rodrigues, e eu sou um estudante de <br/>
          Desenvolvimento de Sistemas, atualmente cursando o terceiro semestre<br/>
          do curso técnico de Desenvolvimento de Sistemas no Senai. Tenho 25<br/>
          anos e sou apaixonado por tecnologia e programação! Durante meus<br/>
          estudos, tenho aprendido diversas habilidades relacionadas ao<br/>
          desenvolvimento de sistemas, incluindo programação em diversas<br/>
          linguagens, banco de dados, desenvolvimento web e mobile, além de ter<br/>
          trabalhado em projetos individuais e em grupo. Além disso, também<br/>
          tenho conhecimento em gerenciamento de projetos e metodologias ágeis,<br/>
          que considero essenciais para o sucesso em qualquer projeto. Fora da<br/>
          sala de aula, gosto de me manter atualizado sobre as novidades em<br/>
          tecnologia e programação, sempre buscando aprender algo novo e<br/>
          desafiador. Também adoro participar de Bootcamps e eventos<br/>
          relacionados a tecnologia para trocar experiências e conhecimentos com<br/>
          outros profissionais. No futuro, espero poder aplicar todo o<br/>
          conhecimento adquirido até agora em projetos reais e ajudar a<br/>
          construir soluções tecnológicas inovadoras. Se você se interessa por<br/>
          tecnologia ou está em busca de um desenvolvedor com paixão pelo que<br/>
          faz, ficarei feliz em conversar com você. Entre em contato comigo.<br/>
        </p>
      </div>
    </div>
    </div>
  );
}
